import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import SwipeableTextMobileStepper from '../Carousel/carousel'
import Grid from '@mui/material/Grid';
import { Divider } from '@mui/material';
import { maxWidth } from '@mui/system';

const Aboutpage= () => {
return (
<Box component="main" sx={{  backgroundImage:
          "url('https://primecomputers.s3.us-west-1.amazonaws.com/Brand/professional-photo-background.jpeg')",p: 3  }} position='relative' margin="auto" marginTop={-7}   >
  <SwipeableTextMobileStepper/>
  
<Box sx={{  p: 3 }} position='relative' margin="auto">
  <Grid >
    <Grid item xs={12} >
    <Typography  sx={{ fontSize: 20  }} color="Black" variant="h6" >
  
    <u><h1>What We Offer</h1></u>
  <br></br>
  <p>
  Your one stop shop for all of your tech and computer needs. 
    We specialize in Pc laptops and Desktops products as well as accessories, and peripherals. 
    As technical creative professionals we understand the needs of film editors, graphic designers, photographers, and general users. We will be able to he﻿lp you fill your needs without breaking the bank.​
​
We take a personal interest in our clients needs. We are always here to help you even after you purchase your computer
. Years down the road if you feel like you need more speed or storage space we will be available to fill your needs.
 We can work with you to as your needs change and as you become a more efficient computer user. 
As technology changes we will make sure your always up to date so you can keep up with a faster moving world. 

  </p>
    


​
</Typography>
    </Grid>
 
  
  </Grid>

 
</Box>
</Box>

)}
export default Aboutpage;