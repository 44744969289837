import {  Routes, Link, Route } from 'react-router-dom';
import Navbar from './components/Navbar/Navbar';
import Aboutpage from './components/BrandPage/Aboutpage';
import Homepage from './components/HomePage/Homepage';
import Contactpage from './components/Contact/Contactpage';
import Footer from './components/Footer/Footer'
import  ServicePage from './components/Services/services'
import  SalesPage from './components/Sales/Sales'
import ErrorPage from './components/ErrorPage/error'

const Root=()=> {

    return (
      <div>
       
        <Navbar />
        
           <Routes>
           <Route exact path="" element={<Homepage/> } />
           <Route exact path="*" element={<ErrorPage/> } />
           <Route exact path="/Home" element={<Homepage/> } />
           <Route exact path="/About" element={<Aboutpage/> } />
           <Route exact path="/Contact" element={<Contactpage/> } />
          
           <Route exact path="/Sales" element={<SalesPage/> } />
           
            </Routes>
          
          
            <Footer/>
      </div>
    );
  }
  export default Root;