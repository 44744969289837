import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Button from '@mui/material/Button';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { Divider } from '@mui/material';
import Stack from '@mui/material/Stack';
import { styled } from '@mui/material/styles';

const SalesPage= () => {
return (
<Box component="main" sx={{  backgroundImage:
          "url('https://primecomputers.s3.us-west-1.amazonaws.com/Brand/76459461-double-exposure-of-professional-businessman-connected-devices-with-world-digital-technology-internet.jpeg')",p: 3 }} 
          position='relative' margin='auto' width={900}>

<Stack container spacing={1}>
<Box sx={{ p: 3 }} position='relative' margin='auto' bgcolor>
<Card sx={{ maxWidth: 800}} >
      <CardMedia
        sx={{ height: 400}}
        image="https://primecomputers.s3.us-west-1.amazonaws.com/Brand/best-laptop-brands-20230420-3-medium.jpeg"
        title=" ​Laptop and Desktop"
      />
      <CardContent>
        <Typography sx={{ backgroundImage:
          "url('https://primecomputers.s3.us-west-1.amazonaws.com/Brand/professional-photo-background.jpeg')"}}gutterBottom variant="h2"component="div">
        ​Laptop and Desktop 
        <Divider></Divider>
        </Typography>
        <Typography sx={{ }} variant="body2" color="text.secondary">
        We have the inventory of almost all brands. 
        <br></br>We have anything you're looking for.
        <br></br> Don’t look further!
         contact us now! or visit our store!
         <br>
         </br><br></br>
        </Typography>
      </CardContent>
     
    </Card>
    <Card sx={{ maxWidth: 800}} >
      <CardMedia
        sx={{ height: 400}}
        image="https://primecomputers.s3.us-west-1.amazonaws.com/05-amazon-basics-computer-accessories.webp"
        title=" ​ Accesories"
      />
      <CardContent>
        <Typography sx={{ backgroundImage:
          "url('https://primecomputers.s3.us-west-1.amazonaws.com/Brand/professional-photo-background.jpeg')"}} gutterBottom variant="h2" component="div">
       Accessories
        </Typography>
        <Divider></Divider>
        <Typography variant="body2" color="text.secondary">
       We carry almost all type of accessories that you would be 
       needing most frequently<br></br> call in today or visit our to inquire about it.
        </Typography>
      </CardContent>
     
    </Card>
    
  
  

    </Box>
</Stack>


   
</Box>

)}
export default SalesPage;