import logo from './logo.svg';
import './App.css';
import Root from "./route"
import { BrowserRouter } from "react-router-dom";


const App = () => {
  return (<BrowserRouter>
  
    <Root/>
    </BrowserRouter>
   )
  
};

export default App;