import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Button from '@mui/material/Button';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import { styled } from '@mui/material/styles';
import { height } from '@mui/system';
import { Divider } from '@mui/material';

const Homepage= () => {
return (
<Box component="main" sx={{  backgroundImage:
          "url('https://primecomputers.s3.us-west-1.amazonaws.com/Brand/76459461-double-exposure-of-professional-businessman-connected-devices-with-world-digital-technology-internet.jpeg')",p: 3 }}
          border={2}  width={1200} height={800} marginTop={-10} position='relative' margin="auto">
<Toolbar />
<Box   width={1100} height={700} position='relative' margin="auto" >
<Grid container spacing={2}>
<Grid item xs={6}>
<Card sx={{ maxWidth: 500 , height:700, border:2}} postion='raltive' margin='auto'>
      <CardMedia
        sx={{ height: 400}}
        image="https://primecomputers.s3.us-west-1.amazonaws.com/Brand/240px-HP_logo_2012.svg.png"
        title="Buy from us"
      />
      <CardContent>
        <Typography gutterBottom variant="h5" component="div">
        <Divider></Divider>
        Buy from us
        </Typography>
        <Typography variant="body2" color="text.secondary">
        Your one stop shop for all of your tech and computer needs. 
    We specialize in PC laptops, accessories, and peripherals. As technical creative professionals we understand the needs of our customer, who are graphic designers, photographers, and general users.
    We will be able to he﻿lp you fill your needs without breaking the bank.  
        </Typography>
      </CardContent>
      <Button margin='auto'href="/Contact">Contact Now</Button>
    </Card>
  </Grid>
  <Grid item xs={6}>
<Card sx={{ maxWidth: 500  , height:700,border:2}}  postion='center' margin='center'>
      <CardMedia
        sx={{ height: 400}}
        image="https://primecomputers.s3.us-west-1.amazonaws.com/Trade/9aadae_38222683a0708be08281736863c7c18a.webp"
        title="Sell to us"
      />
     
      <CardContent>
  
        <Typography gutterBottom variant="h5" component="div">
        <Divider></Divider>
        Sell to us
        </Typography>
        <Typography variant="body2" color="text.secondary">
        If you are in the market for another computer you are most likely upgrading or your old computer has broken. 
      Either way we can help. We can buy your old Mac or PC computer from you or use it as a trade in towards another machine.
       We can also assist you with transferring your important data.
        </Typography>
      </CardContent>
      <Button margin='auto'href="/Contact">Contact Now</Button>
    </Card>
  </Grid>
 
</Grid>

</Box>
    
   
</Box>

)}
export default Homepage;