import { useRouteError } from "react-router-dom";
import Box from '@mui/material/Box';

export default function ErrorPage() {
  
  return (
    <div>
        <Box sx={{height:300}}>
      <p style={{color: "red", fontSize:"30px"}}>
        <h1>
        404 Page Not Found
        </h1>
      </p>
      </Box>
    </div>
  );
}