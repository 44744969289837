import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Button from '@mui/material/Button';
import CallIcon from '@mui/icons-material/Call';
import EmailIcon from '@mui/icons-material/Email';
import StoreIcon from '@mui/icons-material/Store';
import WorkIcon from '@mui/icons-material/Work';
import { bgcolor, borderBottomColor, color, height } from '@mui/system';
import { Divider } from '@mui/material';

const Contactpage= () => {
return (

<Box
          sx={{
            backgroundImage:
          "url('https://primecomputers.s3.us-west-1.amazonaws.com/Brand/76459461-double-exposure-of-professional-businessman-connected-devices-with-world-digital-technology-internet.jpeg')",
    
        height: 500,
        width: 1200,
      
        
        
          }}  position='relative' margin="auto"  border={2}  >


<Typography color="black">
    <u><h1>Contact</h1></u>
  
</Typography>
<Card sx={{ width: 500, height:300 ,border:'10px white', bgcolor:'red' ,margin:'auto', position:'absolute',border:2 }}>
      <CardContent>
        <Typography sx={{ fontSize: 20}} color="#ffffff" gutterBottom>
          
        </Typography>
        <Divider/>
        <br></br>
        <Typography sx={{ fontSize: 20  }} color="#ffffff" variant="h5" component="div">
          <CallIcon/>  +14375185960
        </Typography>
        <Divider/>
        <br></br>
        <Typography sx={{ mb: 1.5 ,fontSize:20}} color="#ffffff">
        <StoreIcon/> <br></br>Sharjah Media City, Al-Messaned, Sharjah, UAE
        </Typography>
        <Divider/>
        <br></br>
        <Typography variant="h6" color="#ffffff">
         <EmailIcon/> mohammadarif.retiwala@epicmove.co
          
        </Typography>
      </CardContent>
      
    </Card>

</Box>



)}
export default Contactpage;